import React, {useState} from 'react';

import "./RoomDialog.css";

const RoomDialog = props => {

    const [isDialogOpen, setIsDialogOpen] = useState(true);

    const [tmpRoomId, setTmpRoomId] = useState(null);
    const {setRoomId} = props;

    const generateCode = event => {
        event.preventDefault();

        fetch(`${window.location.origin}/newRoomId`)
            .then(res => {
                if (res.ok) {
                    res.json()
                        .then(json => {
                            setIsDialogOpen(false);
                            setRoomId(json.roomId);
                        });
                }
                else if (res.status === 403) {
                    alert("The maximum number of rooms has already been created. " +
                          "Please join an existing room or wait for some other room to return available.")
                }
            });
    };

    const handleCodeChange = event => {
        setTmpRoomId(event.target.value);
    };

    const joinRoomWithCode = event => {
        // avoid auto-closing the dialog, we need to check a condition first
        event.preventDefault();

        fetch(`${window.location.origin}/existingRoomId`,
            {
                method: "POST",
                headers: {"Content-Type": "application/json"},
                body: JSON.stringify({roomId: tmpRoomId}),
            })
            .then(res => {
                // room exists
                if (res.ok) {
                    setIsDialogOpen(false);
                    setRoomId(tmpRoomId);
                }
                // room does not exist
                else {
                    alert(`Room ${tmpRoomId} does not exist. Check your spelling or create a new room!`);
                }
            });
    };

    return (
        <>
        <div className="bg"></div>
        <div className="bg bg2"></div>
        <div className="bg bg3"></div>
        <dialog id={"room-code-dialog"} open={isDialogOpen}>
            <form method="dialog">
                <button
                    id={"generate-button"}
                    value={"default"}
                    onClick={generateCode}
                >
                    Create a new game room!
                </button>
                <p id={"or"}>or</p>
                <label htmlFor={"room-code-input"}>Enter room code:</label>
                <br />
                <input
                    id={"room-code-input"}
                    type={"text"}
                    onChange={handleCodeChange}
                    placeholder={"123ABC"}
                    value={tmpRoomId}
                    size={6}  // width in ems: https://developer.mozilla.org/en-US/docs/Web/HTML/Element/Input#size
                >
                </input>
                <br />
                <button
                    id={"join-button"}
                    onClick={joinRoomWithCode}
                >
                    Join an existing room!
                </button>
            </form>
        </dialog>
        </>
    );
};

export default RoomDialog;