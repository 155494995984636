import React, { useRef, useEffect, useState } from 'react';

import "./Game.css";

import * as THREE from 'three';
import {OrbitControls} from "three/examples/jsm/controls/OrbitControls";
import Stats from "three/examples/jsm/libs/stats.module";
import {Sky} from "three/examples/jsm/objects/Sky";


let websocket;  // initialized in useEffect
let isExiting = false;

// from https://codepen.io/WebSeed/pen/ZmXxKz
// integrated with snippets from https://github.com/mrdoob/three.js/blob/master/examples/*.html
const Game = props => {
    const mount = useRef(null);
    // const [isAnimating, setAnimating] = useState(true);
    const controls = useRef(null);

    const {
        roomId,
        userUuid,
        storageUuidKey,
    } = props;

    const [overlayMessage, setOverlayMessage] = useState("");
    const [sticksPerSideState, setSticksPerSideState] = useState(0);

    useEffect(() => {
        // multiplayer handling
        websocket = new WebSocket(
            `ws${window.location.protocol === "https:" ? "s" : ""}://${window.location.host}/game?uuid=${userUuid}&roomId=${roomId}`
        );

        let width = window.innerWidth;
        let height = window.innerHeight;
        let frameId;
        let sky, sun;
        let sticks = [];
        let whiteBalls = [];
        let blackBalls = [];

        // let bulbLight;

        let geometries = [];
        let materials = [];

        const scene = new THREE.Scene();
        const camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 50);
        // https://threejs.org/docs/#api/en/renderers/WebGLRenderer
        const renderer = new THREE.WebGLRenderer({antialias: true});
        const cameraControls = new OrbitControls(camera, renderer.domElement);
        // used to perform raycasting, aka understanding which object is under the mouse
        const mouse = new THREE.Vector2();
        const raycaster = new THREE.Raycaster();

        const distanceBetweenSticks = 0.7;

        const whitePlayerBallsMat = new THREE.MeshPhongMaterial({
            color: 0xffffee,
            specular: 0.8,
            shininess: 100,
        });
        const blackPlayerBallsMat = new THREE.MeshPhongMaterial({
            color: 0x110000,
            specular: 0.8,
            shininess: 100,
        });
        const whitePlayerBallsHighlightMat = new THREE.MeshBasicMaterial({
            color: 0xffffff,
        });
        const blackPlayerBallsHighlightMat = new THREE.MeshBasicMaterial({
            color: 0x2A0A0A,
        });
        materials.push(whitePlayerBallsMat);
        materials.push(blackPlayerBallsMat);
        materials.push(whitePlayerBallsHighlightMat);
        materials.push(blackPlayerBallsHighlightMat);

        // server-given variables
        let clientColor;  // either "w" or "b"
        let sticksPerSide;
        let gameState;

        // initial camera setup
        camera.position.z = -3;  // go back
        camera.position.x = -1;  // go right
        camera.position.y = 4;  // go up
        camera.lookAt(0, 0, 0);

        // show debug stats (FPS, ms)
        // const stats = new Stats();
        // document.getElementById("App").appendChild(stats.dom);

        renderer.setSize(width, height);
        renderer.physicallyCorrectLights = true;
        renderer.outputEncoding = THREE.sRGBEncoding;
        renderer.shadowMap.enabled = true;
        renderer.toneMapping = THREE.ACESFilmicToneMapping;
        // renderer.toneMappingExposure = Math.pow(0.2, 5.0);
        renderer.setPixelRatio(window.devicePixelRatio / 2);  // huge impact on performance

        const addShadowedLight = (x, y, z, color, intensity) => {
            const directionalLight = new THREE.DirectionalLight(color, intensity);
            directionalLight.position.set(x, y, z);

            directionalLight.castShadow = true;

            const d = 5;
            directionalLight.shadow.camera.left = -d;
            directionalLight.shadow.camera.right = d;
            directionalLight.shadow.camera.top = d;
            directionalLight.shadow.camera.bottom = -d;

            directionalLight.shadow.camera.near = 1;
            directionalLight.shadow.camera.far = 30;

            directionalLight.shadow.bias = 0;

            scene.add(directionalLight);
        };

        const initBaseLights = () => {
            scene.add(new THREE.HemisphereLight(0x443333, 0x111122));
            addShadowedLight(2, 1, 2, 0xffff77, 1.35);

            // bulbLight = new THREE.PointLight(0xffee88, 10, 10, 2);
            // bulbLight.position.set(0, -100, 0);
            // scene.add(bulbLight);
        };

        function initSky() {
            sky = new Sky();
            sky.scale.setScalar(450000);
            scene.add(sky);

            materials.push(sky.material);

            const uniforms = sky.material.uniforms;
            uniforms[ 'turbidity' ].value = 10;
            uniforms[ 'rayleigh' ].value = 3;
            uniforms[ 'mieCoefficient' ].value = 0.005;
            uniforms[ 'mieDirectionalG' ].value = 0.7;

            const phi = THREE.MathUtils.degToRad(90 - 2);
            const theta = THREE.MathUtils.degToRad(180);
            sun = new THREE.Vector3();
            sun.setFromSphericalCoords(1, phi, theta);
            uniforms[ 'sunPosition' ].value.copy(sun);
        }

        const initFloor = () => {
            let floorMat = new THREE.MeshStandardMaterial({
                roughness: 0.8,
                color: 0xffffff,
                metalness: 0.2,
                bumpScale: 0.0005,
            });
            const textureLoader = new THREE.TextureLoader();
            textureLoader.load("textures/hardwood2_diffuse.jpg", function (map) {
                map.wrapS = THREE.RepeatWrapping;
                map.wrapT = THREE.RepeatWrapping;
                map.anisotropy = 4;
                map.repeat.set(10, 24);
                map.encoding = THREE.sRGBEncoding;
                floorMat.map = map;
                floorMat.needsUpdate = true;
            });
            textureLoader.load("textures/hardwood2_bump.jpg", function (map) {
                map.wrapS = THREE.RepeatWrapping;
                map.wrapT = THREE.RepeatWrapping;
                map.anisotropy = 4;
                map.repeat.set(10, 24);
                floorMat.bumpMap = map;
                floorMat.needsUpdate = true;
            });
            textureLoader.load("textures/hardwood2_roughness.jpg", function (map) {
                map.wrapS = THREE.RepeatWrapping;
                map.wrapT = THREE.RepeatWrapping;
                map.anisotropy = 4;
                map.repeat.set(10, 24);
                floorMat.roughnessMap = map;
                floorMat.needsUpdate = true;
            });
            materials.push(floorMat);

            const floorGeometry = new THREE.PlaneGeometry(69, 69);
            geometries.push(floorGeometry);

            const floorMesh = new THREE.Mesh(floorGeometry, floorMat);
            floorMesh.receiveShadow = true;
            floorMesh.rotation.x = - Math.PI / 2.0;
            scene.add(floorMesh);
        };

        const initSticks = () => {
            // color: 0x996633,
            //     specular: 0x050505,
            //     shininess: 100,
            const stickMat = new THREE.MeshPhysicalMaterial({
                color: 0x997622,
                metalness: 2,
                roughness: 0.7,
                clearcoat: 0.6,
                clearcoatRoughness: 0.2,
            });
            materials.push(stickMat);

            const stickHeight = 0.5 * sticksPerSide;  // a ball is 0.48 high

            const newStickGeometry = new THREE.CylinderGeometry(
                0.075,
                0.075,
                stickHeight,
            );
            geometries.push(newStickGeometry);

            for (let i = 0; i < sticksPerSide; i++){
                for (let j = 0; j < sticksPerSide; j++) {
                    let newStickMesh = new THREE.Mesh(newStickGeometry, stickMat);
                    newStickMesh.position.set(
                        i * distanceBetweenSticks - ((sticksPerSide - 1) / 2 * distanceBetweenSticks),
                        stickHeight / 2,
                        j * distanceBetweenSticks - ((sticksPerSide - 1) / 2 * distanceBetweenSticks),
                    );
                    newStickMesh.castShadow = true;
                    sticks.push(newStickMesh);
                }
            }
            for (let stickMesh of sticks) {
                scene.add(stickMesh);
            }
        };

        const initBalls = () => {
            const playerBallsNumber = 4 * sticksPerSide * sticksPerSide / 2;  // given 2 players

            const newBallGeometry = new THREE.CapsuleGeometry(0.2, 0.1);
            geometries.push(newBallGeometry);

            for (let i = 0; i < playerBallsNumber; i++) {
                let newBallMesh = new THREE.Mesh(newBallGeometry, whitePlayerBallsMat);
                newBallMesh.castShadow = true;
                newBallMesh.position.set(0, -100, 0);
                whiteBalls.push(newBallMesh);
            }
            for (let i = 0; i < playerBallsNumber; i++) {
                let newBallMesh = new THREE.Mesh(newBallGeometry, blackPlayerBallsMat);
                newBallMesh.castShadow = true;
                newBallMesh.position.set(0, -100, 0);
                blackBalls.push(newBallMesh);
            }
        };

        const initBaseScene = () => {
            initBaseLights();
            initSky();
            initFloor();
            initSticks();
            initBalls();
        };

        const updateOverlayMessage = () => {
            setOverlayMessage(`You are playing as ${clientColor === 'w' ? "white" : "black"}. |
            ${gameState.turn === clientColor ? "Your turn" : "Opponent's turn"}. |
            ${gameState.playersNumber} player${gameState.playersNumber !== 1 ? "s" : ""} connected. |
            Room code: ${roomId}`);
        };

        const renderScene = () => {
            renderer.render(scene, camera);
            // stats.update();
        };

        // must be below renderScene function declaration
        cameraControls.addEventListener('change', renderScene);
        // https://threejs.org/docs/index.html?q=orbit#examples/en/controls/OrbitControls
        cameraControls.enableDamping = false;  // feels unnatural
        cameraControls.maxDistance = 10;  // don't allow distant zoom
        cameraControls.maxPolarAngle = Math.PI / 2 - 0.1;  // don't allow camera below ground

        const handleResize = () => {
            width = window.innerWidth;
            height = window.innerHeight;
            renderer.setSize(width, height);
            camera.aspect = width / height;
            camera.updateProjectionMatrix();
            renderScene();
        };

        const redrawBalls = () => {
            let whiteIndex = 0;
            let blackIndex = 0;
            for (let stickIndex = 0; stickIndex < gameState.ballPositions.length; stickIndex++) {
                for (let heightIndex = 0; heightIndex < sticksPerSide; heightIndex++) {
                    if (gameState.ballPositions[stickIndex][heightIndex] === "w") {
                        if (gameState.lastPosition !== null && stickIndex === gameState.lastPosition[0] && heightIndex === gameState.lastPosition[1]) {
                            whiteBalls[whiteIndex].material = whitePlayerBallsHighlightMat;
                        } else {
                            whiteBalls[whiteIndex].material = whitePlayerBallsMat;
                        }
                        whiteBalls[whiteIndex].position.set(
                            sticks[stickIndex].position.x,
                            0.24 + heightIndex * 0.48,  // magic numbers, empirically determined in a way that the bottom ball stays on top of the floor, and there is space for 4 balls on a stick
                            sticks[stickIndex].position.z,
                        );
                        whiteIndex++;
                    }
                    else if (gameState.ballPositions[stickIndex][heightIndex] === "b") {
                        if (gameState.lastPosition !== null && stickIndex === gameState.lastPosition[0] && heightIndex === gameState.lastPosition[1]) {
                            blackBalls[blackIndex].material = blackPlayerBallsHighlightMat;
                        } else {
                            blackBalls[blackIndex].material = blackPlayerBallsMat;
                        }
                        blackBalls[blackIndex].position.set(
                            sticks[stickIndex].position.x,
                            0.24 + heightIndex * 0.48,  // magic numbers, empirically determined in a way that the bottom ball stays on top of the floor, and there is space for 4 balls on a stick
                            sticks[stickIndex].position.z,
                        );
                        blackIndex++;
                    }
                }
            }

            for (let i = 0; i < whiteIndex; i++) {
                scene.add(whiteBalls[i]);
            }
            for (let i = 0; i < blackIndex; i++) {
                scene.add(blackBalls[i]);
            }

            renderScene();
        };

        const handleClick = event => {
            event.preventDefault();

            // update the picking ray with the camera and pointer position
            mouse.x = (event.clientX / window.innerWidth ) * 2 - 1;
            mouse.y = - (event.clientY / window.innerHeight ) * 2 + 1;
            raycaster.setFromCamera(mouse, camera);

            // calculate objects intersecting the picking ray
            const intersects = raycaster.intersectObjects(sticks);
            // console.log(intersects);
            if (intersects[0]) {
                const intersectedStickIndex = sticks.indexOf(intersects[0].object);
                console.info(`Clicked stick ${intersectedStickIndex + 1} out of ${sticks.length}.`);

                if (clientColor === gameState.turn) {
                    // add new ball at correct coordinate
                    const firstFreeBallPosition = gameState.ballPositions[intersectedStickIndex].indexOf(null);
                    if (firstFreeBallPosition !== -1) {
                        if (gameState.turn === "w") {
                            whiteBalls[gameState.turnsPassedWhite].position.set(
                                sticks[intersectedStickIndex].position.x,
                                0.24 + firstFreeBallPosition * 0.48,  // magic numbers, empirically determined in a way that the bottom ball stays on top of the floor, and there is space for 4 balls on a stick
                                sticks[intersectedStickIndex].position.z,
                            );
                            gameState.ballPositions[intersectedStickIndex][firstFreeBallPosition] = "w";
                            gameState.turnsPassedWhite++;
                            gameState.turn = "b";
                            scene.add(whiteBalls[gameState.turnsPassedWhite]);
                        } else {
                            blackBalls[gameState.turnsPassedBlack].position.set(
                                sticks[intersectedStickIndex].position.x,
                                0.24 + firstFreeBallPosition * 0.48,  // magic numbers, empirically determined in a way that the bottom ball stays on top of the floor, and there is space for 4 balls on a stick
                                sticks[intersectedStickIndex].position.z,
                            );
                            gameState.ballPositions[intersectedStickIndex][firstFreeBallPosition] = "b";
                            gameState.turnsPassedBlack++;
                            gameState.turn = "w";
                            scene.add(blackBalls[gameState.turnsPassedBlack]);
                        }
                        gameState.lastPosition = [intersectedStickIndex, firstFreeBallPosition];

                        // check if current ball makes game end
                        if (gameState.turnsPassedWhite === whiteBalls.length || gameState.turnsPassedBlack === blackBalls.length) {
                            gameState.hasEnded = true;
                        }

                        // let the server know of the move so that it can update other clients
                        websocket.send(`mv_${JSON.stringify(gameState)}`);

                        updateOverlayMessage();
                        redrawBalls();
                    }
                }
            }
        };

        const animate = () => {
            // cube.rotation.x += 0.01;
            // cube.rotation.y += 0.01;

            renderScene();
            frameId = window.requestAnimationFrame(animate);
        };

        const start = () => {
            if (!frameId) {
                frameId = requestAnimationFrame(animate);
            }
        };

        const stop = () => {
            cancelAnimationFrame(frameId);
            frameId = null;
        };

        const cleanAndRestoreScene = () => {
            // delete everything, with garbage collection
            scene.remove.apply(scene, scene.children);
            for (let geom of geometries) {
                geom.dispose();
            }
            for (let mat of materials) {
                mat.dispose();
            }
            geometries = [];
            materials = [];
            sticks = [];
            whiteBalls = [];
            blackBalls = [];
            // reset to initial scene
            initBaseScene();
        }

        mount.current.appendChild(renderer.domElement);
        window.addEventListener('resize', handleResize);
        renderer.domElement.addEventListener('click', handleClick);
        start();

        controls.current = { start, stop };

        // request server for a role (either "w" or "b") sending my client UUID
        websocket.onopen = event => {
            websocket.send(`c_${userUuid}`);
        };

        websocket.onclose = event => {
            // do not save the uuid immediately after having removed it, which prevents the exit
            if (!isExiting) {
                sessionStorage.setItem(storageUuidKey, userUuid);
            }
            setOverlayMessage("You have been disconnected. Please reload the page.");
        };

        websocket.onerror = event => {
            setOverlayMessage("You have been disconnected. Please reload the page.");
        };

        websocket.onmessage = event => {
            if (event.data.startsWith("cok_")) {
                const res = JSON.parse(event.data.substring(4));
                clientColor = res.clientColor;
                sticksPerSide = res.sticksPerSide;
                gameState = res.gameState;
                initBaseScene();
                // send heartbeat to server, hoping that it works to keep the connection alive -- should use ping/pong
                setInterval(() => {
                    websocket.send("🫀");
                }, 2000);
            }
            else if (
                event.data.startsWith("mvup_") ||
                event.data.startsWith("npup_")
            ) {
                gameState = JSON.parse(event.data.substring(5));
            }
            else if (event.data === "udnok") {
                alert("You can't undo. Either you have not moved last, you have already used your undo, this is the first move, or the game has ended.");
            }
            else if (event.data.startsWith("mvnok_")) {
                alert("You can't move, the game has ended. Please reset or change the amount of sticks per side.");
                // restore previous state
                gameState = JSON.parse(event.data.substring(6));
                cleanAndRestoreScene();
            }
            else if (
                event.data.startsWith("rtok_") ||
                event.data.startsWith("skok_") ||
                event.data.startsWith("udok_")
            ) {
                if (
                    event.data.startsWith("rtok_") ||
                    event.data.startsWith("udok_")
                ) {
                    gameState = JSON.parse(event.data.substring(5));
                }
                else if (event.data.startsWith("skok_")) {
                    const res = JSON.parse(event.data.substring(5));
                    sticksPerSide = res.sticksPerSide;
                    gameState = res.gameState;
                }

                cleanAndRestoreScene();
            }
            else if (event.data === "end") {
                alert(`${gameState.turn === "w" ? "⚫️ Black" : "⚪️ White"} won!`);
            }

            setSticksPerSideState(sticksPerSide);

            updateOverlayMessage();
            redrawBalls();
        };

        return () => {
            stop();
            window.removeEventListener('resize', handleResize);
            mount.current.removeChild(renderer.domElement);

            // scene.remove(cube);
            // geometry.dispose();
            // material.dispose();
        };
    }, []);

    // callback of the ui-controls sticksPerSide select
    const handleSticksPerSideChange = event => {
        const newSticksPerSide = event.target.value;
        websocket.send(`skup_${newSticksPerSide}`);
    };

    // needed on iOS for example
    const fallbackCopyTextToClipboard = text => {
        const textArea = document.createElement("textarea");
        textArea.value = text;
        // avoid scrolling to bottom
        textArea.style.top = "0";
        textArea.style.left = "0";
        textArea.style.position = "fixed";
        document.body.appendChild(textArea);
        textArea.focus();
        textArea.select();
        const successful = document.execCommand('copy');
        document.body.removeChild(textArea);
    };

    const copyTextToClipboard = text => {
        if (!navigator.clipboard) {
            fallbackCopyTextToClipboard(text);
            return;
        }
        navigator.clipboard.writeText(text);
    };

    // useEffect(() => {
    //     if (isAnimating) {
    //         controls.current.start();
    //     } else {
    //         controls.current.stop();
    //     }
    // }, [isAnimating]);

    return (
        <div
            id={"game-canvas"}
            className="vis"
            ref={mount}
        >
            <p id={"overlay-message"}>
                {overlayMessage.includes("disconnected") ?
                    overlayMessage :
                    <>
                        {/*we assume that the code is the last 6 characters of the overlayMessage*/}
                        {overlayMessage.slice(0, -6)}
                        <span
                            id={"room-code"}
                        >
                            {overlayMessage.slice(-6)}
                        </span>
                        {/*don't show the Copy button while the page is loading*/}
                        {overlayMessage &&
                            <button
                                id={"copy-room-code-button"}
                                onClick={() => copyTextToClipboard(roomId)}
                            >
                                <i className={"fa fa-regular fa-copy"}></i> Copy
                            </button>
                        }
                    </>
                }
            </p>
            <div id={"ui-controls-friendly"}>
                <button
                    id={"undo-button"}
                    onClick={() => websocket.send("undo")}
                >
                    <i className={"fa fa-solid fa-rotate-left"}></i> Undo
                </button>
                <button
                    id={"reload-button"}
                    onClick={() => {
                        sessionStorage.setItem(storageUuidKey, userUuid);
                        window.location.reload();
                    }}
                >
                    <i className={"fa fa-solid fa-rotate"}></i> Reload
                </button>
            </div>
            <div id={"ui-controls-dangerous"}>
                <label
                    id={"sticksPerSide-label"}
                    htmlFor="sticksPerSide-select"
                >
                    Sticks per side:
                </label>
                <select
                    id={"sticksPerSide-select"}
                    onChange={handleSticksPerSideChange}
                    defaultValue={sticksPerSideState}
                    value={sticksPerSideState}  // required to change the value on-the-fly
                >
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                    <option>5</option>
                    <option>6</option>
                    <option>7</option>
                    <option>8</option>
                    <option>9</option>
                    <option>10</option>
                </select>
                <button
                    id={"reset-button"}
                    onClick={() => websocket.send("rst")}
                >
                    <i className={"fa fa-solid fa-power-off"}></i> Reset
                </button>
                <button
                    id={"exit-button"}
                    onClick={() => {
                        isExiting = true;
                        sessionStorage.removeItem(storageUuidKey);
                        window.location.reload();
                    }}
                >
                    <i className={"fa fa-solid fa-person-running"}></i> Exit
                </button>
            </div>
        </div>
    );
};

export default Game;