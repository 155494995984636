import {useEffect, useState} from "react";
import './App.css';
import Game from "./components/Game";
import RoomDialog from "./components/RoomDialog";
import {v4 as uuidv4} from "uuid";


const storageUuidKey = "3dc4_uuid";  // used to save/restore "cookie"

function App() {

    const [roomId, setRoomId] = useState(null);
    const [userUuid, setUserUuid] = useState(null);

    useEffect(() => {
        // the player had previously connected
        if (storageUuidKey in sessionStorage) {
            const tmpUserUuid = sessionStorage.getItem(storageUuidKey);

            fetch(`${window.location.origin}/reconnectedRoomId`,
                {
                    method: "POST",
                    headers: {"Content-Type": "application/json"},
                    body: JSON.stringify({uuid: tmpUserUuid}),
                })
                .then(res => {
                    // room found
                    if (res.ok) {
                        res.json()
                            .then(json => {
                                setUserUuid(tmpUserUuid);
                                setRoomId(json.roomId);
                            });
                    }
                    // can't find room
                    else {
                        setUserUuid(uuidv4());
                    }
                });
        }
        else {
            setUserUuid(uuidv4());
        }
    }, []);

    return (
        <div id="App">
            {/*only show RoomDialog if the user hasn't previously connected*/}
            {/*conditionally load the Game component so the first open WebSocket is already the correct one*/}
            {roomId === null ?
                <RoomDialog
                    setRoomId={setRoomId}
                /> :
                <Game
                    roomId={roomId}
                    userUuid={userUuid}
                    storageUuidKey={storageUuidKey}
                />
            }
        </div>
    );
}

export default App;
